

.home{
  max-width: 560px;
  height: 100vh;
  display: flex;  
  flex-direction: column;
  background: #fff;
  margin: auto;
  overflow: hidden;
  padding-bottom: constant(safe-area-inset-bottom);
  .header{
    padding: 15px 20px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    position: fixed;
    background: #fff;
    max-width: 560px;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    align-items: center;
    .logo{
      height: 24px;
    }
    .wallet{
      button{
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #39F881;
        border: none;
        outline: none;
        box-sizing: border-box;
        padding: 0;    
        &:active,&:focus{
          border: none;
          outline: none;
        }
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .main{
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .step{
      position:absolute;
      left: 0;
      top:100%;
      width: 100%;
      height:100%;
      padding-top: 72px;
      box-sizing: border-box;
      background-color: #fff;
    }
    .step1{
      height: 100%;
      display: flex;
      flex-direction: column;
      .banner{
        display: flex;
      }
      .game{
        flex: 1;
        // background: url(../../assets//image//game.png) no-repeat top center;
        // background-size: cover;
        background-color: #70B5F7;
        position: relative;
        .cloud1{
          position: absolute;
          left: 39px;
          top: 33px;
          width: 20.52px;
          height: 12.31px;
        }
        .cloud2{
          position: absolute;
          right: 25px;
          top: 27px;
          width: 61.55px;
          height: 20.52px;
        }
        .cloud3{
          position: absolute;
          left: 134px;
          top: 161px;
          width: 20.52px;
          height: 12.31px;
        }
        .sun{
          position: absolute;
          right: 79px;
          top: 65px;
          width: 41.03px;
          height: 41.03px;
        }
        .shu-left{
          position: absolute;
          left: 0px;
          bottom: 0px;
          width: 143.64px;
          height: 32.83px;
        }
        .shu-right{
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 77.98px;
          height: 24.62px;
        }
        .game-text{
          // height: 65px;
          position: absolute;
          width: 100%;
          top: 55px;
          left: 50%;
          transform: translateX(-50%);
          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          text-align: center;
          font-family: NeueBit-bold;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px; /* 90% */
          text-shadow: #000 3px 0 0, #000 0 3px 0, #000 -3px 0 0, #000 0 -3px 0;
        }
        .Create{
          width: 240px;
          height: 40px;
          position: absolute;
          top: 200px;
          left: 50%;
          transform: translateX(-50%);
          background: url(../../assets/image/creat-btn-gg.png) no-repeat center;
          background-size: 100% 100%;
          border: none;
          outline: none;
          font-family: HarmonyOS Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            margin-right: 5px;
          }
          &:active,:focus{
            border: none;
            outline: none;
          }
        }
      
      }
    }
    .step2{
      height: 100%;
      overflow: scroll;
      .from{
        padding: 32px;
        .avatar{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .avatar-img{
            border-radius: 800px;
            overflow: hidden;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .camera{
            width: 27px;
            height: 27px;
            position: absolute;
            right: 0;
            bottom: 0;
            background: #fff;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .file {
            position: relative;
            display: inline-block;
            border-radius: 800px;
            // overflow: hidden;
            width: 80px;
            height: 80px;
            // background: url('../../assets/image/default-avatar.png') no-repeat center;
            // background-size: cover;
          }
          .file input {
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0;/*关键点*/
            filter: alpha(opacity=0);/*兼容ie*/
            font-size: 100px;/* 增大不同浏览器的可点击区域 */
            cursor: pointer;
          }
        }
        .name{
          margin:  24px 0;
          label{
            span{
              color: #FF4665;
            }
            color: #000;
            /* Regular/正文2 */
            font-family: HarmonyOS Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .from-box{
            margin-top: 8px;
            position: relative;
            input{
              height: 44px;
              width: 100%;
              border-radius: 8px;
              padding: 14px 16px;
              box-sizing: border-box;
              border: 1px solid #ECEEF1;
            }
            .count{
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
              color:  #C2C2C5;
              text-align: right;
              font-feature-settings: 'clig' off, 'liga' off;
              /* Regular/正文2 */
              font-family: HarmonyOS Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .definition{
          label{
            span{
              color: #FF4665;
            }
            color: #000;
            /* Regular/正文2 */
            font-family: HarmonyOS Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .from-box{
            margin-top: 8px;
            position: relative;
            textarea{
              resize: none;
              height: 170px;
              width: 100%;
              border-radius: 8px;
              padding: 16px;
              box-sizing: border-box;
              border: 1px solid #ECEEF1;
            }
            .count{
              position: absolute;
              right: 15px;
              bottom: 10px;
              transform: translateY(-50%);
              color:  #C2C2C5;
              text-align: right;
              font-feature-settings: 'clig' off, 'liga' off;
              /* Regular/正文2 */
              font-family: HarmonyOS Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .Create-btn{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 48px;
          button{
            width: 100%;
            max-width: 240px;
            height: 40px;
            border-radius: 8px;
            outline: none;
            border: none;
            background-color: #39F881;
            color: var(--unnamed, #000);
            text-align: center;
            /* Bold/正文1 */
            font-family: HarmonyOS Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &:disabled{
              background: #ECEEF1;
              color: #C2C2C5;
            }
          }
        }
       
      }
    }
    .step3{
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 104px 20px 47px;
      box-sizing: border-box;
      .follow{
        border-radius: 12px;
        flex: 1;
        background: #E3FAEB;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px 64px;
        margin-bottom: 0;
        box-sizing: border-box;
        // justify-content: center;
        .pic{
          width: 206.61px;
        }
        h4{
          color: #000;
          text-align: center;
          /* Bold/Title3 */
          font-family: HarmonyOS Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 44px;
          margin-bottom: 8px;
        }
        p{
          margin: 0;
          color: #000;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          /* Regular/正文2 */
          font-family: HarmonyOS Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .follow-btn{
        display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 48px;
          button{
            width: 100%;
            max-width: 240px;
            height: 40px;
            border-radius: 8px;
            outline: none;
            border: none;
            background-color: #39F881;
            color: var(--unnamed, #000);
            text-align: center;
            /* Bold/正文1 */
            font-family: HarmonyOS Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &:disabled{
              background: #ECEEF1;
              color: #C2C2C5;
            }
          }
      }
    }
    .step4{
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 136px 40px 47px;
      box-sizing: border-box;
      .Claim{
        border-radius: 12px;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        // padding: 50px 64px;
        margin-bottom: 0;
        box-sizing: border-box;
        // justify-content: center;
        .pic{
          padding: 26px;
          width: 200px;
          height: 200px;
          box-sizing: border-box;
          background: url('../../assets/image/nft-border.png') no-repeat center;
          background-size: 100% 100%;
          .border{
            overflow: hidden;
            display: flex;
            align-items: center;
            height: 148px;
            width: 100%;
          }
        }
        h4{
          width: 100%;
          color: #000;
          text-align: center;
          font-family: HarmonyOS Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 32px;
          margin-bottom: 8px;
        }
        .pass{
              width: 100%;
              color: #8D8D92;
              text-align: left;
              font-family: HarmonyOS Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              ul{
                padding-left: 15px;
              }
        }
        p{
        //   width: 100%;
        //   max-height: 96px;
        //   overflow: scroll;
        //   margin: 0;
        //   color: #8D8D92;
        //   text-align: center;
        //   font-family: HarmonyOS Sans;
        //   font-size: 14px;
        //   font-style: normal;
        //   font-weight: 400;
        //   line-height: normal;
        }
      }
      .Claim-btn{
        display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 48px;
          button{
            width: 100%;
            max-width: 240px;
            height: 40px;
            border-radius: 8px;
            outline: none;
            border: none;
            background-color: #39F881;
            color: var(--unnamed, #000);
            text-align: center;
            /* Bold/正文1 */
            font-family: HarmonyOS Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &:disabled{
              background: #ECEEF1;
              color: #C2C2C5;
            }
          }
      }
    }
    .step5{
      height: 100%; 
      display: flex;
      flex-direction: column;
      padding: 88px 20px 0px;
      box-sizing: border-box;
      position: relative;
      h1{
        margin: 0;
        color: var(--unnamed, #000);
        text-align: center;
        font-family: HarmonyOS Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25; 
      }
      .adv-box{
        flex: 1;
        padding: 0 30px;
        padding-top: 25px;

      }
      .download{
        position: absolute;
        bottom: 47px;
        left: 0;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        gap: 12px;
        a{
          flex: 1;
          height: 40px;          
          background: #39F881;
          font-family: HarmonyOS Sans;
          font-size: 14px;
          font-weight: bold;
          line-height: 16.38px;
          text-align: center;
          letter-spacing: 0px;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          border-radius: 8px;
          img{
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}

.camear_model{
  position: fixed;
  // z-index: -9999;
  // display: none;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  .camear_model-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .camear_model-bg-main{
      position: absolute;
      max-width: 560px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.50);
    }
  }
  .container{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
   
    .camear_model-content{
      max-width: 560px;
      width: 100%;
      // transform: translateX(-50%);
      background-color: #fff;
      border-radius: 20px 20px 0 0;
      box-sizing: border-box;
      // height: 200px;
      display: flex;
      flex-direction: column;
      padding: 28px 16px 50px;
      button{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 12px;
        border-radius: 8px;
        background-color: #ECEEF1;
        border: none;
        outline: none;
        color: #000;
        margin-bottom: 16px;
        &:focus,&:active{
          border: none;
          outline: none;
        }
        img{
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
      }
    }
  }
}


.wallet_model{
  position: fixed;
  // z-index: -9999;
  // display: none;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  .wallet_model-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .wallet_model-bg-main{
      position: absolute;
      max-width: 560px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.50);
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;

  .content{
    width: 80%;
    min-width: 295px;
    background-color: #fff;
    position: relative;
    max-width: 500px;
    padding:  30px 15px;
    box-sizing: border-box;
    border-radius: 8px;
    .close{
      width: 28px;
      height: 28px;
      position: absolute;
      right: 8px;
      top: 8px;
    }
    h2{
      font-family: HarmonyOS Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      margin-bottom: 30px;
    }
    .btn-group{
      button{
        background: #ECEEF1;
        width: 100%;
        height: 48px;
        border-radius: 8px;
        border: none;
        outline: none;
        margin-bottom: 16px;
        img{
          height: 16px;
        }
        &:active,&:hover{
          border: none;
          outline: none;
        }
        
      }
    }
  }
}













.btn {
  color: #fff;
  font-size: 30px;
  background: black;
  width: 300px;
  line-height: 60px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.enableEthereumButton {
  background: #0569ff;
  border-color: #0569ff;
}
.text {
  margin: 5px;
  font-size: 30px;
}
.result {
  font-size: 25px;
}